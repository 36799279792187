<template>
  <b-container :style="this['events/handleMinHeight']" class="fontStandard">
    <b-row>
      <b-col cols="0" md="0" lg="1" xl="2"></b-col>
      <b-col cols="12" md="12" lg="10" xl="8">
        <h1 class="text-center mt-5">
          <i class="big_text fas fa-user-plus pb-1" />
          <br />
          <b-badge pill variant="light">
            <strong class="fontStandard text-primary">
              <BTextUppercase>Inscription</BTextUppercase>
            </strong>
          </b-badge>
        </h1>
        <br />

        <b-form>
          <b-row>
            <b-col class="mx-auto text-white max_width_400" cols="12" md="6">
              <!-- Formulaire -->
              <b-form-group>
                Compte utilisateur:
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-user"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="content_username"
                    class="form-control"
                    placeholder="Identifiant"
                    type="text"
                    v-model="content.username"
                    :formatter="formatter_lowercase"
                    :state="check_username(content.username)"
                  />
                  <b-form-invalid-feedback
                    :state="check_username(content.username)"
                  >4 caractères min, 30 caractères max</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>

              <b-form-group>
                Adresse email:
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-envelope"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="content_email"
                    class="form-control"
                    placeholder="Adresse email"
                    autocomplete="off"
                    type="email"
                    v-model="content.email"
                    :state="check_mail(content.email)"
                  />
                  <b-form-invalid-feedback
                    :state="check_mail(content.email)"
                  >Indiquez une adresse email correcte</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>

              <b-form-group>
                Mot de passe:
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-lock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="content_password1"
                    class="form-control"
                    placeholder="Créer un mot de passe"
                    :type="get_type_password"
                    v-model="passwd1"
                    autocomplete="new-password"
                    :state="check_password(passwd1, passwd2)"
                  />
                  <b-input-group-prepend>
                    <b-button @click="generatePassword()" variant="primary">
                      <i class="fas fa-key" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-lock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    id="content_password2"
                    class="form-control"
                    placeholder="Confirmer ce mot de passe"
                    :type="get_type_password"
                    v-model="passwd2"
                    :state="check_password(passwd1, passwd2)"
                  />

                  <b-input-group-prepend>
                    <b-button @click="changeTypePassword = !changeTypePassword" variant="primary">
                      <i
                        :class="[
                            'fas',
                            changeTypePassword ? 'fa-eye-slash' : 'fa-eye'
                          ]"
                      />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-invalid-feedback
                    :state="check_password(passwd1, passwd2)"
                  >8 caractères min, 1 majuscule, 2 minuscules, 2 chiffres</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>

              <b-form-group
                v-if="
                    account.axios_data && account.axios_data.picture
                  "
              >
                Recopier ce code:
                <b-input-group>
                  <b-img
                    width="500"
                    fluid
                    thumbnail
                    center
                    rounded="top"
                    block
                    :src="account.axios_data.picture"
                    alt="captcha"
                  />
                  <b-input-group-prepend>
                    <b-button @click="renewCaptcha()" variant="primary">
                      <i class="fas fa-undo-alt" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-input
                    class="form-control"
                    placeholder="Captcha"
                    type="text"
                    v-model="captcha"
                    @click="captcha = ''"
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <b-link
                  v-b-modal.theTermsOfUse
                  class="text-white"
                >Lire les conditions générales d'utilisation...</b-link>
                <b-form-checkbox
                  switch
                  class="text-left"
                  v-model="acceptTheTermsOfUse"
                  @click="acceptTheTermsOfUse = !acceptTheTermsOfUse"
                >
                  <b-badge
                    tag="i"
                    :variant="acceptTermsOfUse"
                  >J'accepte les Conditions générales d'utilisation</b-badge>
                </b-form-checkbox>
              </b-form-group>

              <b-modal
                id="theTermsOfUse"
                title="Conditions générales d'utilisation"
                ok-only
                centered
                header-bg-variant="dark"
                body-bg-variant="dark"
                footer-bg-variant="dark"
                ok-variant="light"
                header-close-variant="light"
                header-text-variant="light"
                header-border-variant="dark"
                hide-footer
                scrollable
                size="lg"
              >
                <TermsOfUse />
              </b-modal>
            </b-col>

            <!-- Seconde partie -->
            <b-col class="mx-auto text-white max_width_400" cols="12" md="6">
              <b-form-group>
                Fiche personnelle:
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-address-book"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <select class="form-control" v-model="extra_user_info.gender">
                    <option value="male" selected>Homme</option>
                    <option value="female">Femme</option>
                  </select>
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-address-book"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="extra_user_info_firstname"
                    class="form-control"
                    placeholder="Prénom"
                    type="text"
                    v-model="extra_user_info.firstName"
                    :formatter="formatter_capitalize"
                    maxlength="50"
                    :state="check_firstname(extra_user_info.firstName)"
                    required
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-address-book"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="extra_user_info_lastname"
                    class="form-control"
                    placeholder="Nom"
                    type="text"
                    v-model="extra_user_info.lastName"
                    :formatter="formatter_uppercase"
                    maxlength="50"
                    :state="check_lastname(extra_user_info.lastName)"
                    required
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-phone"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="extra_user_info_phone"
                    class="form-control"
                    placeholder="N° téléphone"
                    type="text"
                    v-model="extra_user_info.phone"
                    :state="check_phone(extra_user_info.phone)"
                    required
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group>
                Date de naissance:
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-birthday-cake"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-datepicker
                    id="content_birthday"
                    v-model="extra_user_info.age"
                    no-highlight-today
                    close-button
                    dark
                    no-flip
                    close-button-variant="outline-primary"
                    hide-header
                  />
                  <b-popover
                    target="content_birthday"
                    placement="topright"
                    title="Facultatif"
                    variant="info"
                    triggers="hover focus"
                    content="Date de naissance"
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group>
                Adresse / Code postal / Ville:
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-address-card"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="extra_user_info_address"
                    class="form-control"
                    placeholder="Adresse"
                    type="text"
                    v-model="extra_user_info.adress"
                    maxlength="255"
                    :state="check_adress(extra_user_info.adress)"
                    required
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-address-card"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="extra_user_info_citycode"
                    class="form-control"
                    placeholder="Code postal"
                    type="number"
                    v-model="extra_user_info.citycode"
                    maxlength="10"
                    :state="check_citycode(extra_user_info.citycode)"
                    required
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="fa fa-address-card"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    id="extra_user_info_city"
                    class="form-control"
                    placeholder="Ville"
                    type="text"
                    v-model="extra_user_info.city"
                    :formatter="formatter_uppercase"
                    maxlength="50"
                    :state="check_city(extra_user_info.city)"
                    required
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="mx-auto text-white max_width_400" cols="12" md="6">
              <b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  @click="register"
                  :disabled="checkRegister"
                  block
                >
                  <i class="fas fa-user-plus" /> Création du compte
                </b-button>
              </b-form-group>
              <p class="text-center">
                Vous avez un compte?
                <b-link tag="a" to="login">Connexion</b-link>
              </p>
            </b-col>
            <b-col class="mx-auto text-white max_width_400" cols="12" md="6"></b-col>
          </b-row>

          <!-- Formulaire -->
        </b-form>
      </b-col>
      <b-col cols="0" md="0" lg="1" xl="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Form from "@/mixins/Form";

export default {
  components: {
    BTextUppercase: () => import("@/components/BTextUppercase"),
    TermsOfUse: () => import("@/components/TermsOfUse"),
  },
  mixins: [Form],
  computed: {
    ...mapState(["account"]),
    ...mapGetters(["events/handleMinHeight"]),
  },
  mounted() {
    this.$store.dispatch("axios/http_reading", true);
    // this.$root.axios("get", "api/captcha/ask");
    this.$root.handleGetRequest("api/captcha/ask", true);
  },
  beforeDestroy() {
    this.$root.renewToken();
  },
  methods: {
    async register(e) {
      e.preventDefault();

      if (!this.acceptTheTermsOfUse) {
        return this.$store.dispatch("listeners/bvToast", {
          title: `Il manque quelque chose ...`,
          variant: "warning",
          msg: "Vous devez accepter les conditions d'utilisations",
          time: 15000,
          timeout: 0,
        });
      } else {
        await this.$store.dispatch("account/register", {
          vm: this,
          content: this.content,
          passwd1: this.passwd1,
          passwd2: this.passwd2,
          extra_user_info: this.extra_user_info,
          captcha: this.captcha,
        });
      }
    },
  },
};
</script>
